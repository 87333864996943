document.addEventListener("DOMContentLoaded", function () {
    const sections = document.querySelectorAll("div[id]");
    const navLinks = document.querySelectorAll(".header-nav li a");

    function updateActiveClass() {
        let scrollPosition = window.scrollY + window.innerHeight * 0.3;
        let activeFound = false;

        sections.forEach((section) => {
            const id = section.getAttribute("id");
            const link = document.querySelector(`.header-nav li a[href="#${id}"]`);
            if (!link) return;

            const rect = section.getBoundingClientRect();
            const sectionTop = rect.top + window.scrollY;
            const sectionBottom = sectionTop + section.offsetHeight;

            if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                navLinks.forEach((nav) => nav.parentElement.classList.remove("is-active"));
                link.parentElement.classList.add("is-active");
                activeFound = true;
            }
        });

        if (!activeFound) {
            navLinks.forEach((nav) => nav.parentElement.classList.remove("is-active"));
        }
    }

    if (window.innerWidth > 768) {
        window.addEventListener("scroll", updateActiveClass);
        updateActiveClass();
    }
});
